import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css/bundle";
import slide1 from "../../assets/images/slide1.png";
import slide2 from "../../assets/images/slide2.png";
import slide3 from "../../assets/images/slide3.png";
import slide4 from "../../assets/images/slide4.png";
import slide5 from "../../assets/images/slide5.png";
import slide6 from "../../assets/images/slide6.png";
import slide7 from "../../assets/images/slide7.png";
import slide8 from "../../assets/images/slide8.png";
import slide9 from "../../assets/images/slide9.png";
import slide10 from "../../assets/images/slide10.png";

function Carousel() {
	return (
		<div className="swiperContainer">
			<Swiper
				modules={[EffectFade, Autoplay]}
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 2000,
					disableOnInteraction: true,
				}}
				effect={"fade"}
				loop
				slidesPerView={1}
			>
				<SwiperSlide>
					<img src={slide1} alt="carousel1" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide2} alt="carousel2" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide3} alt="carousel3" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide4} alt="carousel4" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide5} alt="carousel5" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide6} alt="carousel6" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide7} alt="carousel7" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide8} alt="carousel8" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide9} alt="carousel9" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide10} alt="carousel10" />
				</SwiperSlide>
			</Swiper>
		</div>
	);
}

export default Carousel;
