import { Link } from "react-router-dom";
import { PortableText } from "@portabletext/react";

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.toLocaleString("default", { month: "long" });
	const year = date.getFullYear();
	const time = date.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});

	const daySuffix = (day) => {
		if (day > 3 && day < 21) return "th";
		switch (day % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	};

	return `${day}${daySuffix(day)} ${month}, ${year} at ${time} CST`;
}

function EventCard({ event }) {
	console.log("Event card data:", event);
	const formattedDate = formatDate(event.date);

	return (
		<Link className="link" to={`/events/${event._id}`}>
			<div className="eventCard eventCardMain">
				<div className="eventItems">
					<div className="eventInfo">
						<h3 className="eventTitle">{event.name}</h3>
						<div className="eventHeadline">
							{event.headline && <PortableText value={event.headline} />}
						</div>
						<p className="eventDate">
							<span>
								<i className="fa-solid fa-calendar-days"></i>
							</span>{" "}
							{formattedDate}
						</p>
						{event.venue && event.venue.name && (
							<p className="eventLocation">
								<span>
									<i className="fa-solid fa-location-dot"></i>
								</span>{" "}
								{event.venue.name}
							</p>
						)}
						{event.eventType && (
							<p className="eventType">
								<span>
									<i className="fa-solid fa-circle-exclamation"></i>
								</span>{" "}
								{event.eventType === "In-person" ? "In-person" : "Virtual"}
							</p>
						)}
					</div>
					<button aria-label="Learn More" className="ctaSecondary eventBtn">
						Learn More
						<span>
							<i className="fa-solid fa-chevron-right"></i>
						</span>
					</button>
				</div>
				<div className="eventImageContainer">
					{event.image && (
						<img
							src={event.image}
							alt={`${event.name} Poster`}
							className="eventImage"
						/>
					)}
				</div>
			</div>
		</Link>
	);
}

export default EventCard;
