import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../sanity/client";
import { PortableText } from "@portabletext/react";
import { Link } from "react-router-dom";

const EventDetail = () => {
	const { id } = useParams();
	const [event, setEvent] = useState(null);
	const [loading, setLoading] = useState(true);
	const [timeLeft, setTimeLeft] = useState({});

	useEffect(() => {
		const fetchEvent = async () => {
			try {
				const data = await client.fetch(
					`*[_type == "event" && _id == $id]{
						_id,
						name,
						slug,
						eventType,
						date,
						doorsOpen,
						venue->{
							name,
							address,
							website,
							googleMapsLocation,
							contactInfo
						},
						speaker->{
							name,
							bio,
							role
						},
						"image": image.asset->url,
						headline,
						description,
						googleMapsLocation,
						capacity,
						ticketInformation[]{
							price,
							availability,
							purchaseLink,
							"additionalInfo": additionalInfo[] {
								...,
								_type,
								markDefs[]{
									...,
									_type
								}
							}
						},
						sponsors[]{
							name,
							website
						}
					}[0]`,
					{ id }
				);
				setEvent(data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching event:", error);
				setLoading(false);
			}
		};

		fetchEvent();
	}, [id]);

	useEffect(() => {
		if (event) {
			const calculateTimeLeft = () => {
				const eventDate = new Date(event.date);
				const now = new Date();
				const difference = eventDate - now;

				let timeLeft = {};

				if (difference > 0) {
					timeLeft = {
						days: Math.floor(difference / (1000 * 60 * 60 * 24)),
						hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
						minutes: Math.floor((difference / 1000 / 60) % 60),
						seconds: Math.floor((difference / 1000) % 60),
					};
				} else {
					timeLeft = {
						days: 0,
						hours: 0,
						minutes: 0,
						seconds: 0,
					};
				}

				return timeLeft;
			};

			const intervalId = setInterval(() => {
				setTimeLeft(calculateTimeLeft());
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [event]);

	if (loading) {
		return (
			<div className="loadingAnimationContainer">
				<l-ring
					size="40"
					stroke="5"
					bg-opacity="0"
					speed="2"
					color="black"
				></l-ring>
			</div>
		);
	}

	if (!event) {
		return <div>Event not found</div>;
	}

	return (
		<div className="eventDetailsSection desktopMaxWidth">
			<div className="desktopMaxWidth eventDetailsBackBtn">
				<Link to="/events">
					<button aria-label="Back" className="cta">
						<i className="fa-solid fa-circle-chevron-left"></i>Back
					</button>
				</Link>
			</div>

			<div className="desktopMaxWidth allEventDetailsContainer">
				<div className="eventDetailsImgContainer">
					{event.image && (
						<img src={event.image} alt={`${event.name} Poster`} />
					)}
				</div>

				<div className="eventDetailsContainer">
					<div className="eventDetail">
						<h2>{event.name.toUpperCase()}</h2>
						{event.headline && (
							<div className="eventHeadline">
								<PortableText value={event.headline} />
							</div>
						)}
					</div>
					<div className="eventDetail">
						{event.description && (
							<div className="eventDescription">
								<PortableText value={event.description} />
							</div>
						)}
					</div>
					<div className="eventDetailsGrouped">
						<div className="eventDetail">
							<p className="eventIconText">
								<span>
									<i className="fa-solid fa-calendar-days"></i>
								</span>
								Date & Time
							</p>
							<p>
								{new Date(event.date).toLocaleDateString("en-GB", {
									day: "numeric",
									month: "long",
									year: "numeric",
								})}
							</p>
							<p>
								{new Date(event.date).toLocaleTimeString("en-GB", {
									hour: "2-digit",
									minute: "2-digit",
									hour12: true,
								})}{" "}
								- CST
							</p>
						</div>
						{event.eventType && (
							<div className="eventDetail">
								<p className="eventIconText">
									<span>
										<i className="fa-solid fa-circle-exclamation"></i>
									</span>
									Type
								</p>
								<p>{event.eventType}</p>
							</div>
						)}
						{event.doorsOpen && (
							<div className="eventDetail">
								<p className="eventIconText">
									<span>
										<i className="fa-solid fa-door-open"></i>
									</span>
									Doors Open
								</p>
								<p>{event.doorsOpen} minutes before</p>
							</div>
						)}
						{event.speaker && (
							<div className="eventDetail">
								<p className="eventIconText">
									<span>
										<i className="fa-solid fa-microphone-lines"></i>
									</span>
									Speaker
								</p>
								<p>{event.speaker.name}</p>
								{event.speaker.role && <p>Role: {event.speaker.role}</p>}
								{event.speaker.bio && <p>{event.speaker.bio}</p>}
							</div>
						)}
						{event.capacity && (
							<div className="eventDetail">
								<p className="eventIconText">
									<span>
										<i className="fa-solid fa-users"></i>
									</span>
									Capacity
								</p>
								<p>{event.capacity} people</p>
							</div>
						)}
						{event.venue && (
							<div className="eventVenueContainer">
								<div className="eventDetail">
									<p className="eventIconText">
										<span>
											<i className="fa-solid fa-location-dot"></i>
										</span>
										Venue
									</p>
									<p>{event.venue.name}</p>
									{event.venue.address && <p>{event.venue.address}</p>}
									{event.venue.website && (
										<a
											href={event.venue.website}
											target="_blank"
											rel="noopener noreferrer"
										>
											Venue Website
										</a>
									)}
									{event.venue.googleMapsLocation && (
										<a
											href={event.venue.googleMapsLocation}
											target="_blank"
											rel="noopener noreferrer"
										>
											View on Google Maps
										</a>
									)}
									{event.venue.contactInfo && (
										<p>Contact: {event.venue.contactInfo}</p>
									)}
								</div>
							</div>
						)}
					</div>
					<div className="ticketInformationContainer"></div>
					{/* Ticket Information */}
					{event.ticketInformation && event.ticketInformation.length > 0 && (
						<div className="ticketSection">
							<p className="eventIconText">
								<span>
									<i className="fa-solid fa-ticket"></i>
								</span>
								Ticket Information
							</p>
							{event.ticketInformation.map((ticket, index) => (
								<div key={index} className="ticketItem eventDetail">
									{ticket.price && ticket.purchaseLink ? (
										<>
											<p className="emphasizedText">Price: ${ticket.price}</p>
											<p>
												Status:{" "}
												{ticket.availability === "available"
													? "Available"
													: "Sold Out"}
											</p>
											<a
												href={ticket.purchaseLink}
												target="_blank"
												rel="noopener noreferrer"
											>
												Purchase Ticket
											</a>
										</>
									) : (
										ticket.additionalInfo && (
											<div className="ticketAdditionalInfo">
												<PortableText value={ticket.additionalInfo} />
											</div>
										)
									)}
								</div>
							))}
						</div>
					)}
					<div className="eventSponsorsContainer"></div>

					{/* Sponsors Information */}
					{event.sponsors && event.sponsors.length > 0 && (
						<div className="sponsorsSection">
							<p className="eventIconText">
								<span>
									<i className="fa-solid fa-handshake"></i>
								</span>
								Sponsors
							</p>
							<div className="sponsorsContainer">
								{event.sponsors.map((sponsor, index) => (
									<div key={index} className="sponsorItem eventDetail">
										<p className="emphasizedText">{sponsor.name}</p>
										{sponsor.website && (
											<a
												href={sponsor.website}
												target="_blank"
												rel="noopener noreferrer"
											>
												Visit Sponsor Website
											</a>
										)}
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className=" desktopMaxWidth countdownSection">
				{timeLeft && (
					<div className="countdownContainer">
						<h2>Countdown to Event</h2>
						<div className="countdownCard">
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.days}</p>
								<p>Days</p>
							</div>
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.hours}</p>
								<p>Hours</p>
							</div>
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.minutes}</p>
								<p>Minutes</p>
							</div>
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.seconds}</p>
								<p>Seconds</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EventDetail;
